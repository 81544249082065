import { cx } from "class-variance-authority";
import type { TFunction } from "i18next";
import {
    type ReactNode,
    type SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Switch } from "@/components/Switch";
import { toast } from "react-toastify";
import { CentreCard, type DateTimeFormValue } from ".";

import { Loader } from "@/components/Loader";
import { getPosition } from "@/lib/geo";
import { graphql } from "@/lib/gql";
import { Image } from "@/lib/imgproxy";
import { getCentreImage } from "@/utils/centre";
import { parseTime, unique } from "@/utils/index";
import { useQuery } from "@apollo/client";
import { addHours, parse, setHours, setMinutes, startOfDay } from "date-fns";
import { useRouter } from "next/router";
import AdvertisementBannerV2 from "../AdvertisementBannerV2";

const query = graphql(`
    query pelangganSearchResults($request: CourtsiteSearchRequest!) {
        courtsiteSearch(request: $request) {
            uid
            name
            services
            metadata
        }
    }
`);
const locationQuery = graphql(`
    query pelangganSearchResultLocation {
        locations {
            uid
            name
            coordinates {
                latitude
                longitude
            }
            city
        }
    }
`);
const onlineServicesQuery = graphql(`
    query pelangganSearchResultService($categoryId: ID!) {
        onlineServicesByCategory(categoryId: $categoryId) {
            uid
            tenantId
        }
    }
`);
const availabilitiesQuery = graphql(`
    query pelangganCentreSearchUseAvailabilities(
        $request: [ServiceAvailabilityRequest!]!
        $quotationsRequest: [QuotationRequest!]!
    ) {
        serviceAvailabilities(request: $request) {
            isAvailable
            tenantId
            serviceId
        }
        quotations(request: $quotationsRequest) {
            quotationFor
            timeFullyAccounted
        }
    }
`);

type TFunc = TFunction<["components/SearchPage", "common"]>;
type Coords = { latitude: number; longitude: number } | undefined;
type SearchResultProps = {
    categoryId: string | null;
    categoryName?: string;
    locationId?: string | null;
    dateTimeRange: DateTimeFormValue;
    byMaxDistance: number | null;
    setShowFilter: (v: boolean) => void;
    children?: ReactNode;
};
type Centre = {
    uid: string;
    name: string;
    cityAndState?: string;
    categoryIds: string[];
};
type UseCentreSearchData = { centres: Centre[]; loading: boolean };
type NoResultProps = {
    t: TFunc;
    areCentersUnavailable: boolean;
    hasCategoryId: boolean;
    hasLocationId: boolean;
    isDtRangeValid: boolean;
    hasMaxDistance: boolean;
    setShowFilter: (v: SetStateAction<boolean>) => void;
};

export const SearchResults = ({
    categoryId,
    categoryName,
    locationId,
    dateTimeRange,
    byMaxDistance,
    setShowFilter,
    children,
}: SearchResultProps): JSX.Element => {
    const { t, ready } = useTranslation(["components/SearchPage", "common"]);
    const [showAvailable, setShowAvailable] = useState(false);
    const [coords, setCoords] = useState<Coords>();
    const router = useRouter();

    const { centres, loading: centreLoading } = useCentreSearch(
        coords,
        categoryId,
        byMaxDistance,
    );
    const { data, loading: onlineServiceLoading } = useQuery(
        onlineServicesQuery,
        {
            fetchPolicy: "cache-and-network",
            skip: !categoryId,
            variables: categoryId ? { categoryId } : undefined,
        },
    );
    const { data: locationData, loading: locationLoading } = useQuery(
        locationQuery,
        { fetchPolicy: "cache-and-network" },
    );

    const { date, time, meridiem, duration } = dateTimeRange;
    const locations = useMemo(
        () => locationData?.locations ?? [],
        [locationData],
    );

    let start: Date | undefined;
    if (date && time) {
        const [hours, minutes] = parseTime(time);
        const adjustedHours =
            meridiem === "PM" && hours < 12
                ? hours + 12
                : meridiem === "AM" && hours === 12
                  ? 0
                  : hours;
        const baseDate = startOfDay(parse(date, "yyyy-MM-dd", new Date()));
        const dateWithHour = setHours(baseDate, adjustedHours);
        start = setMinutes(dateWithHour, minutes);
    }
    const onlineServicesByCategory = data?.onlineServicesByCategory ?? [];
    const shouldCheckAvailability =
        !!start &&
        !!duration &&
        centres.length > 0 &&
        onlineServicesByCategory.length > 0;
    const availabilitiesRequest = !shouldCheckAvailability
        ? []
        : onlineServicesByCategory
              .filter((s) => centres.some((c) => c.uid === s.tenantId))
              .map((val) => ({
                  tenantId: val.tenantId,
                  serviceId: val.uid,
                  startDt: (start as Date).toISOString(),
                  endDt: addHours(
                      start as Date,
                      Number.parseFloat(duration),
                  ).toISOString(),
              }));

    const { availableCentres, loading: serviceAvailabilitiesLoading } =
        useAvailabilities(availabilitiesRequest);
    const loading =
        centreLoading || onlineServiceLoading || locationLoading || !ready;
    const isCentreAvailable = (uid: string): boolean =>
        availableCentres.includes(uid);

    const filteredCentres = centres.filter(
        (c) =>
            !showAvailable ||
            !shouldCheckAvailability ||
            isCentreAvailable(c.uid),
    );
    const areCentersUnavailable =
        filteredCentres.length === 0 && centres.length > 0;

    useEffect(() => {
        const getCurrentCoords = async (): Promise<Coords[]> => {
            try {
                const { coords } = await getPosition();
                const { latitude, longitude } = coords;
                return [{ latitude, longitude }];
            } catch (err) {
                if (err.code === 1)
                    toast.error(
                        t(
                            "common:get_current_position_permission_denied",
                            "Unable to retrieve your location. Please grant the Courtsite website permission to use your location.",
                        ),
                    );
                else
                    toast.error(
                        t(
                            "common:get_current_position_fail",
                            "Unable to retrieve your location",
                        ),
                    );
                return [];
            }
        };

        const getLocation = async (): Promise<void> => {
            if (locationId && locationId === "current_location") {
                const [currentCoords] = await getCurrentCoords();
                setCoords(currentCoords);
            } else
                setCoords(
                    locations.find((l) => locationId && locationId === l.uid)
                        ?.coordinates,
                );
        };
        getLocation();
    }, [locationId, locations, t]);

    const isDtRangeValid = !!date && !!time && !!meridiem && !!duration;
    const areAllFieldsEmpty = !(!!categoryId || !!locationId || isDtRangeValid);
    const timeArr = time?.split(":");
    const changeTimeToInt =
        timeArr?.[0] && timeArr[1]
            ? Number.parseInt(timeArr[0]) + Number.parseInt(timeArr[1]) / 60
            : undefined;
    let child: ReactNode;
    if (loading || !router.isReady)
        child = (
            <div className="py-[116px]">
                <Loader
                    loading
                    loadingClassName="w-80"
                    loadingText={
                        <div className="flex flex-col">
                            <span>
                                {t("resultLoadingFirst", "Sit back and relax")}
                            </span>
                            <span>
                                {t(
                                    "resultLoadingSecond",
                                    "Searching for you now...",
                                )}
                            </span>
                        </div>
                    }
                />
            </div>
        );
    else if (!centres.length || areAllFieldsEmpty || areCentersUnavailable)
        child = (
            <NoResults
                t={t}
                areCentersUnavailable={areCentersUnavailable}
                hasCategoryId={!!categoryId}
                hasLocationId={!!locationId}
                isDtRangeValid={isDtRangeValid}
                hasMaxDistance={!!byMaxDistance}
                setShowFilter={setShowFilter}
            />
        );
    else
        child = (
            <ol className="m-0 grid auto-cols-[332px] grid-cols-1 justify-center gap-x-4 gap-y-5 md:grid-cols-2 lg:group-data-[filter=hide]:grid-cols-3 lg:group-data-[filter=show]:grid-cols-2">
                {filteredCentres.map((c, index) => {
                    const pathname = "/centre/[orgName]/[orgID]";
                    const { asPath: from } = router;

                    const query = {
                        orgName: c.name,
                        orgID: c.uid,
                        from,
                    };
                    const href = { pathname, query };
                    const bookNowHref = {
                        pathname: `${pathname}/select`,
                        query: {
                            categoryId,
                            date,
                            time: changeTimeToInt,
                            meridiem,
                            duration,
                            ...query,
                        },
                    };
                    const isAvailable =
                        !shouldCheckAvailability || isCentreAvailable(c.uid);

                    return (
                        <>
                            <CentreCard
                                key={c.uid}
                                bookNowHref={bookNowHref}
                                href={href}
                                loading={serviceAvailabilitiesLoading}
                                isAvailable={isAvailable}
                                t={t}
                                {...c}
                            />
                            {(index + 1) % 6 === 0 && (
                                <li className="col-span-1 w-full md:col-span-2 lg:col-span-3">
                                    <AdvertisementBannerV2
                                        page="showSearch"
                                        categoryNames={categoryName}
                                    />
                                </li>
                            )}
                        </>
                    );
                })}
                {filteredCentres.length < 6 && (
                    <li className="col-span-full w-full">
                        <AdvertisementBannerV2
                            page="showSearch"
                            categoryNames={categoryName}
                        />
                    </li>
                )}
            </ol>
        );

    return (
        <section className="max-w-[1056px] px-4 lg:mx-auto lg:px-0">
            <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <span className="text-blue-grey-400">
                        <span
                            className={cx(
                                (areAllFieldsEmpty || loading) && "hidden",
                            )}
                        >
                            {t("venueResult", "{{count}} venue(s)", {
                                count: filteredCentres.length,
                            })}
                        </span>
                    </span>
                    {children}
                </div>
                {shouldCheckAvailability && (
                    <div className="flex items-center gap-2 rounded-lg border border-solid border-primary-100 p-4">
                        <Switch
                            checked={showAvailable}
                            onClick={() => setShowAvailable((v) => !v)}
                        />
                        <label className="typography-sub text-blue-grey-900">
                            {t(
                                "seeAvailableVenues",
                                "See available venues only",
                            )}
                        </label>
                    </div>
                )}
                {child}
            </div>
        </section>
    );
};

const useAvailabilities = (
    requests: {
        tenantId: string;
        serviceId: string;
        startDt: string;
        endDt: string;
    }[],
): { availableCentres: string[]; loading: boolean } => {
    const { data, loading } = useQuery(availabilitiesQuery, {
        fetchPolicy: "network-only",
        variables: {
            request: requests,
            quotationsRequest: requests.map((r) => ({
                tenantId: r.tenantId,
                setFor: r.serviceId,
                start: r.startDt,
                end: r.endDt,
            })),
        },
        skip: requests.length === 0,
    });
    const quotations = data?.quotations;
    const availabilities = data?.serviceAvailabilities;
    if (!quotations || !availabilities) {
        return { availableCentres: [], loading };
    }

    const hasQuotation = (sid: string): boolean =>
        quotations.some((q) => q.timeFullyAccounted && q.quotationFor === sid);
    const availableCentres = availabilities
        .filter((sa) => sa.isAvailable && hasQuotation(sa.serviceId))
        .map((a) => a.tenantId);

    return { availableCentres: unique(availableCentres), loading };
};

const useCentreSearch = (
    coords: Coords,
    categoryId: string | null,
    byMaxDistance?: number | null,
): UseCentreSearchData => {
    const { data, loading } = useQuery(query, {
        variables: {
            request: {
                latitude: coords?.latitude,
                longitude: coords?.longitude,
                maxDistance: byMaxDistance && byMaxDistance * 1000,
                services: categoryId ? [categoryId] : [],
            },
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
    });

    if (!data?.courtsiteSearch) return { centres: [], loading };

    const centres = data.courtsiteSearch.map(({ ...c }) => {
        const metadata = JSON.parse(c.metadata);
        return {
            ...c,
            categoryIds: c.services,
            cover: getCentreImage(c.metadata, categoryId ?? undefined),
            cityAndState: metadata.cityAndState ?? "",
        };
    });

    return { centres, loading };
};

export const NoResults = ({
    t,
    areCentersUnavailable,
    hasCategoryId,
    hasLocationId,
    isDtRangeValid,
    hasMaxDistance,
    setShowFilter,
}: NoResultProps): JSX.Element => {
    const areAllFieldsEmpty = !(
        hasCategoryId ||
        hasLocationId ||
        isDtRangeValid
    );
    let title: string = t("noResultTitle", "No results found");
    let description: string = t(
        "noResultDescription",
        "Please clear your filters and try again.",
    );
    let action: string | undefined;
    if (hasLocationId && hasMaxDistance && !areCentersUnavailable) {
        description = t(
            "noResultDescription",
            "Try expanding the distance or changing the location for more results. ",
            { context: "byMaxDistance" },
        );
        action = t("noResultAction", "Set filters", {
            context: "byMaxDistance",
        });
    } else if (areCentersUnavailable) {
        title = t("noResultTitle", "No available venues found", {
            context: "noAvailableCentres",
        });
        description = t(
            "noResultDescription",
            "Please consider adjusting your filters and try again.",
            { context: "noAvailableCentres" },
        );
    } else if (areAllFieldsEmpty) {
        description = t(
            "noResultDescription",
            "Please select a sport or location to begin your search.",
            { context: "allFieldsEmpty" },
        );
    }

    return (
        <div className="flex flex-col items-center gap-6 py-[116px]">
            <div className="flex flex-col items-center gap-2 text-center">
                <div className="relative size-[70px]">
                    <Image
                        src="/images/no_results.png"
                        alt="Venue not found"
                        layout="fill"
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <div className="text-blue-grey-600">{title}</div>
                    <span className="typography-sub italic text-blue-grey-400">
                        {description}
                    </span>
                </div>
            </div>
            {!areAllFieldsEmpty ||
                (!areCentersUnavailable && (
                    <Button
                        variant="text"
                        onClick={() => setShowFilter((v) => !v)}
                    >
                        {action}
                    </Button>
                ))}
        </div>
    );
};
